.header {
    background-color: #fdfdfd;
    min-height: 20vh;
    display: flex;
    flex-direction: row; /* Cambiado a row */
    align-items: center; /* Cambiado a center */
    padding-right: 40px;
    justify-content: space-between; /* Distribuye el espacio entre los elementos */
    font-size: calc(10px + 2vmin);
    color: rgb(180, 108, 108);
}

.header-title {
    margin: 0;
    font-size: 1.5em; /* Tamaño de fuente de 1.5em */
    text-align: center; /* Alinea el texto al centro */
    flex-grow: 1; /* Permite que el título ocupe el espacio disponible */
    font-family: 'Arial', sans-serif; /* Cambiado a Arial */
    color: #0d63c6; /* Cambiado a un tono más oscuro */
}




.home{
    padding: 0;
    margin: 0;
}

.mt-4 {
    margin-top: 1.5rem;
}


/* home.css */

body {
    font-family: 'Arial', sans-serif;
    background-color: #f5f5f5;
    margin: 0;
    padding: 0;
}







.nav-links {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.nav-link {
    color: white;
    text-decoration: none;
    margin: 0 15px;
    font-size: 1.2em;
}

.nav-link:hover {
    text-decoration: underline;
}

.content {
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 600px;
    margin: 20px auto;
}

.invoice-section, .user-form {
    margin-bottom: 20px;
}

.invoice-form {
    display: flex;
    flex-direction: column;
}

.invoice-form .form-group {
    margin-bottom: 15px;
}

.user-form .form-group {
    margin-bottom: 15px;
}

button {
    width: 100%;
}

.mt-4 {
    margin-top: 1.5rem;
}


.invoice-form {
    max-width: 400px;
    margin: auto;
  }
  
  .invoice-form .form-group {
    margin-bottom: 1.5rem;
  }
  
  .invoice-form .form-label {
    font-weight: bold;
    color: #495057;
  }
  
  .invoice-form .form-control {
    border-radius: 4px;
    border: 1px solid #ced4da;
    padding: 0.5rem;
    width: -webkit-fill-available;
    margin: 0.5rem 0;
    margin-bottom: 1.4rem;
    margin-top: 1.4rem;
  }
  
  .invoice-form .form-control:focus {
    border-color: #f00c0c;
    box-shadow: 0 0 5px rgba(255, 0, 140, 0.25);
  }
  
  .invoice-form button {
    width: 100%;
    padding: 0.5rem;
    border-radius: 8px;
    border-style: none;
    font-weight: bold;
    background-color: #33daa8;
    color: white;
    
  }

  .btn-invoice-code:disabled {
    background-color: rgb(163, 163, 163);
    border-color: rgb(172, 172, 172);
    cursor: not-allowed;
}


  .checkbox-terms {
    display: flex;
    align-items: center;
    margin-top: 1rem;

  }
